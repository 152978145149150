import { useAuthStore } from '@/app/(auth)/context/authStore';
import mixpanel, { Dict, Query } from 'mixpanel-browser';

let mixpanelInstance: MixPanel | null = null;

export const getMixPanelClient = () => {
  if (!mixpanelInstance) {
    mixpanelInstance = new MixPanel();
  }
  return mixpanelInstance;
};

class MixPanel {
  constructor () {
    mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '');
    const userFromStore = useAuthStore.getState().user;
    const completeName = `${userFromStore?.firstName ?? ''} ${userFromStore?.lastName ?? ''}`;
    this.set(userFromStore?.email ?? '', userFromStore?.uuid ?? '', completeName);
  }

  identify (id: string) {
    mixpanel.identify(id);
  }

  alias (id: string) {
    mixpanel.alias(id);
  }

  track (event: string, props?: Dict) {
    mixpanel.track(event, props);
  }

  track_links (query: Query, name: string) {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    });
  }

  set (email: string, uid: string, name: string) {
    mixpanel.identify(email);
    mixpanel.people.set({
      $email: email,
      USER_ID: uid,
      $name: name,
    });
  }
}

export default MixPanel;
