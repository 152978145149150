import { Usermodel } from '@alfred-co/types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

interface AuthStoreState {
  isLoading: boolean
  isAuth: boolean
  errorMessage: string
  infoMessage: string
  user: Usermodel | null
};

interface AuthStoreActions {
  setIsLoading: (value: boolean) => void
  setIsAuth: (value: boolean) => void
  setErrorMessage: (value: string) => void
  setInfoMessage: (value: string) => void
  setUser: (value: Usermodel) => void
};

export type AuthStoreType = AuthStoreState & AuthStoreActions;

export const useAuthStore = create<AuthStoreType>()(
  devtools(
    (set) => ({
      isLoading: false,
      isAuth: false,
      errorMessage: '',
      infoMessage: '',
      user: null,
      setIsLoading: (value: boolean) => set({ isLoading: value }),
      setIsAuth: (value: boolean) => set({ isAuth: value }),
      setErrorMessage: (value: string) => set({ errorMessage: value }),
      setInfoMessage: (value: string) => set({ infoMessage: value }),
      setUser: (value: Usermodel) => set({ user: value }),
    }),
    {
      name: 'authStore',
      enabled: process.env.NODE_ENV === 'development',
    }
  )
);
