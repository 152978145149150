'use client';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { es } from 'yup-locales';
import { Button, Input, Spacer, Link, Card, CardBody } from '@alfred-co/ui/components';
import analytics from '@/utils/analitycs';
import useAuth from '../../hooks/useAuth';

Yup.setLocale(es);

const defaultSignFormData = {
  username: '',
  'current-password': '',
};

const SigninSchema = Yup.object().shape({
  username: Yup.string()
    .label('Correo electrónico')
    .email()
    .trim()
    .required(),
  'current-password': Yup.string()
    .label('Contraseña')
    .required(),
});

const LoginForm = () => {
  const { onLogin, isLoading, errorMessage } = useAuth();

  return (
    <div className='flex flex-col gap-2 w-full'>

      <div className='text-red-500' />
      {
        errorMessage && (
          <Card
            isBlurred
            className='border-none bg-error/60 dark:bg-error/50 max-w-[610px] mb-2'
            shadow='sm'
          >
            <CardBody>
              <p>{errorMessage}</p>
            </CardBody>
          </Card>
        )
      }
      <Formik
        initialValues={defaultSignFormData}
        validationSchema={SigninSchema}
        onSubmit={(values) => {
          const castValues = SigninSchema.cast(values);
          void onLogin(castValues.username, values['current-password']);
        }}
      >
        {({
          isValid,
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Input
                isRequired
                color='alfred'
                name='username'
                fullWidth
                placeholder='Escribe aquí tu correo electrónico'
                value={values?.username}
                label='Correo electrónico'
                onChange={handleChange}
                onBlur={handleBlur}
                errorMessage={errors?.username}
                autoComplete='email'
                labelPlacement='outside'
                size='lg'
                radius='md'
                isInvalid={!!errors?.username}
              />
              <Spacer y={12} />
              <Input
                isRequired
                color='alfred'
                type='password'
                name='current-password'
                fullWidth
                placeholder='Escribe aquí tu contraseña'
                value={values['current-password']}
                label='Contraseña'
                labelPlacement='outside'
                size='lg'
                onChange={handleChange}
                onBlur={handleBlur}
                radius='md'
                autoComplete='current-password'
              />

              <Spacer y={14} />
              <div className='relative inline-block'>
                <Link href='/recover' className='bg-primary-gradient inline-block font-medium text-transparent bg-clip-text' underline='always' onClick={() => analytics.event('go_to_recover_password')}>
                  Olvidé mi contraseña
                </Link>
                <span className='absolute bottom-0 left-0 w-full h-px bg-primary-gradient' />
              </div>
              <Spacer y={20} />
              <div className='flex justify-center'>
                <Button
                  type='submit'
                  size='lg'
                  radius='sm'
                  className='bg-primary-gradient text-dark-main font-bold w-[265px] disabled:bg-none disabled:bg-complementary-gray-dark disabled:text-complementary-gray-light'
                  isLoading={isLoading}
                  isDisabled={!isValid}
                  onPress={() => analytics.event('login')}
                >
                  Iniciar Sesión
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LoginForm;
