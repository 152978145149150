import { Dict } from 'mixpanel-browser';
import { getMixPanelClient } from './mixpanel';

const mixpanelIsActive = () => process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

const event = (event: string, properties?: Dict) => {
  if (mixpanelIsActive()) {
    const mp = getMixPanelClient();
    mp.track(event, properties);
  }
};

const analytics = {
  event,
};

export default analytics;
