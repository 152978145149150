
export const translateCognitoError = (error: string) => {
  switch (error) {
    case 'UserNotFoundException':
      return 'El usuario no existe';
    case 'NotAuthorizedException':
      return 'Usuario o contraseña incorrectos';
    case 'UserNotConfirmedException':
      return 'El usuario no ha sido confirmado';
    case 'CodeMismatchException':
      return 'El código de verificación no es correcto';
    case 'ExpiredCodeException':
      return 'El código de verificación ha expirado';
    case 'InvalidParameterException':
      return 'El correo electrónico no es válido';
    case 'InvalidPasswordException':
      return 'La contraseña no es válida';
    case 'UsernameExistsException':
      return 'El usuario ya existe';
    case 'LimitExceededException':
      return 'Demasiados intentos, intenta más tarde';
    case 'InternalErrorException':
      return 'Error interno, intenta más tarde';
    default:
      return 'Error desconocido';
  }
};
